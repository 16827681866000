<template>
  <div class="wrap">
    <NavBar :title="$t('Personal.email.title')" left-arrow @click-left="$router.go(-1)"></NavBar>
    <Form @submit="onSubmit">
      <Field v-model="emailValue" name="email" :label="$t('Personal.Page.email')"
        :placeholder="$t('Personal.Page.email')" :rules="rules" />
      <div style="margin: 16px">
        <Button round block type="info" native-type="submit" class="submit">{{
      $t("Personal.email.submit")
    }}</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { NavBar, Field, Form, Button,Toast } from "vant";
import { $post } from "@/utils/request";
import { emailRule } from "@/utils/tools"
import { userinfo } from "@/api/use"

export default {
  components: {
    NavBar,
    Field,
    Form,
    Button,
    Toast
  },
  data() {
    return {
      email: "",
      rules: [
        { required: true, message: this.$t('Personal.email.emailplaceholder') },
        { validator: this.emailValidator, message: this.$t('Personal.email.hintCorrect') }
      ],
      userInfo: {}
    };
  },
  created(){
    this.getUserInfo()
  },
  methods: {
    emailValidator(val) {
      return emailRule.test(val)
    },
    async onSubmit(values) {
      if (!values.email) return;
      try {
        const {data:{ret,data,msg}} = await $post("/users/email/bind", {
          email: values.email,
          code: '112233'
        });
        if(ret == 1){
          Toast(msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
          return 
        }
        Toast(msg)
      } catch (error) { }
    },
    async getUserInfo() {
      const {
        data: { ret, data }
      } = await userinfo()
      if (ret === 1) {
        this.userInfo = data;
      } else {
      }
    },
  },
  computed: {
    emailValue: {
      get() {
        return this.email || this.userInfo.email;
      },
      set(val) {
        this.email = val;
        return val;
      },
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__content {
  background-color: #ff0000;

  .van-icon-arrow-left {
    color: #fff;
  }

  .van-nav-bar__title {
    color: #fff;
  }
}

.submit {
  background-color: #ff0000;
  color: #fff;
  border: none;
}

.van-cell {
  background-color: #fff;

  /deep/ .van-field__label {
    color: #000;
  }

  /deep/ .van-field__control {
    color: #000;
  }
}

.van-cell::after {
  border-bottom: none;
}
.warp{
  background-color: #fff;
}
</style>